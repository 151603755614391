<template>
  <SectionWrap nopad bg-color="inverted">
    <div ref="stickyBtns" class="stickyBtns">
      <span class="cta-course-title">
        {{ title }}
      </span>
      <ButtonIcon
        v-for="(btn, index) in buttons"
        :key="index"
        data-test="cta-panel-title"
        :class="btn.class"
        :href="btn.link"
        :icon="btn.icon"
        :icon-left="btn.iconLeft"
      >
        {{ btn.text }}
      </ButtonIcon>
    </div>
  </SectionWrap>
</template>
<script>
import { SectionWrap, ButtonIcon } from '@unimelb/pattern-lib-vue';

export default {
  name: 'StickyPannelButtons',
  components: { SectionWrap, ButtonIcon },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="postcss">
.stickyBtns {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding-block: var(--sp-2);
  width: 100%;

  .btn + .btn {
    margin-top: 0;
  }

  .cta-course-title {
    font-weight: var(--fw-semibold);
    font-size: 1.125rem;
    color: white;
    align-self: center;
    margin-right: auto;
    margin-bottom: 0;
    display: none;
  }

  .btn--secondary,
  .btn--text {
    display: none;
  }

  @media (--bp-tablet) {
    grid-template-columns: 1fr 1fr;

    .btn--secondary,
    .btn--text {
      display: block;
    }
  }

  @media (--bp-x-desktop) {
    display: flex;
    justify-content: center;
    align-items: center;
    .cta-course-title {
      display: block;
    }
  }
}
</style>
