<template>
  <main
    v-if="course && !course.loading && course.error"
    id="main"
    class="bg-white"
  >
    <SectionWrap>
      <ErrorMessage title="This page is not ready yet!">
        <pre>{{ course.errorMessage }}</pre>
        <p>
          <a class="btn btn--xsml" href="/">Click here to go back</a>
        </p>
      </ErrorMessage>
    </SectionWrap>
  </main>
  <main v-else-if="course" id="main" class="bg-white">
    <HeaderBanner
      class="header-banner-overview"
      :type="bannerType"
      :image="course.thumbnail_url"
      :name="course.title"
      :banner-cta="bannerCta"
      :statistics="course.statistics"
      :codes="codes"
      :alert="bannerAlert"
    />

    <UserProfileToggleAlt v-if="!isMajor" :course="course" />

    <KeyFactsPanel
      v-if="!isMajor"
      :course="course"
      :key-facts-points="keyFactsPoints"
      :key-facts-buttons="ctaButtonsArr"
      :key-facts-buttons-additional="ctaButtonsAdditional"
    />

    <div id="observerSensor" ref="observerSensor" />

    <div key="index" ref="stickyPanel" class="stickyPanel hide">
      <StickyPannelButtons
        :buttons="ctaButtonsArr.slice(0, 2)"
        :title="course.title"
      />
      <HeaderNavLinks
        ref="navlinks"
        :offering-type="course.offering_type"
        :category="course.category_title"
        :offset="-55"
      />
    </div>

    <nuxt-child :course="course" :residency="residency" />
    <SlimlineCtaContainer />
  </main>
</template>

<script>
import { SectionWrap } from '@unimelb/pattern-lib-vue';
import { get } from '@/helpers/get';
import { stringifyArraySemiColon } from '@/helpers/stringifyArraySemiColon';
import { analyticsSetPage, formatDollars } from '@/helpers/client';
import { structureTitle } from '~/helpers/navigationTitles';
import StickyPannelButtons from '~/components/StickyPannelButtons.vue';
import HeaderBanner from '~/components/HeaderBanner.vue';
import ErrorMessage from '~/components/ErrorMessage.vue';
import HeaderNavLinks from '~/components/HeaderNavLinks.vue';
import imgPlaceholder from '~/assets/placeholder-new.jpg';
import UserProfileToggleAlt from '~/components/UserProfileToggleAlt.vue';
import KeyFactsPanel from '~/components/KeyFactsPanel.vue';
import SlimlineCtaContainer from '~/components/SlimlineCtaContainer.vue';

const rootUrl = process.env.ROOT_URL;

export default {
  components: {
    SectionWrap,
    HeaderBanner,
    HeaderNavLinks,
    ErrorMessage,
    UserProfileToggleAlt,
    KeyFactsPanel,
    StickyPannelButtons,
    SlimlineCtaContainer,
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
      observer: null,
      observerTriggered: false,
    };
  },
  async fetch({ store, route }) {
    await store.dispatch(
      'offerings/setOfferingData',
      route.params.slug.toLowerCase(),
    );

    const offering = store.getters['offerings/offering'];

    if (offering && !offering.error) {
      const breadcrumbs = [
        { text: 'Study', href: rootUrl },
        { text: 'Find a course', href: `${rootUrl}${process.env.basePath}` },
        {
          text: offering.title,
          href: `${rootUrl}${process.env.basePath}/courses/${
            route.params.component
          }/${route.params.slug.toLowerCase()}`,
        },
      ];

      if (route.meta[1] && route.meta[1].breadcrumb !== 'Overview') {
        let breadcrumbText = route.meta[1].breadcrumb;
        if (breadcrumbText === 'Course structure') {
          breadcrumbText = structureTitle(offering.offering_type);
        }
        breadcrumbs.push({
          text: breadcrumbText,
          href: `${route.fullPath}`,
        });
      }

      await store.dispatch('setBreadcrumbs', breadcrumbs);
    }
  },

  head() {
    const meta = [
      { name: 'description', content: this.course.metadata.seo_description },
      { name: 'faculty', content: this.course.metadata.faculty },
      { name: 'page_type', content: 'course' },
      { name: 'course_code', content: this.course.metadata.offering_code },
      { name: 'offering_type', content: this.course.offering_type },
      { name: 'offering_level', content: this.course.offering_level },
      {
        name: 'offering_category',
        content: this.course.category,
      },
      { name: 'search_class', content: this.course.metadata.search_class },
      { name: 'study_area_search_filter', content: this.topInterestsMeta },
      { name: 'title_search_display', content: this.course.title },
      {
        name: 'matrix_last_updated',
        content: this.course.metadata.seo_last_updated_time,
      },
      { name: 'mspace', content: this.course.metadata.mspace },
      {
        property: 'og:title',
        content: `${this.course.title} ${this.$t('app.meta.title.suffix')}`,
      },
      {
        property: 'og:description',
        content: this.course.metadata.seo_description,
      },
      {
        property: 'og:image',
        content: this.course.thumbnail_url
          ? this.course.thumbnail_url
          : imgPlaceholder,
      },
      { name: 'twitter:card', content: 'summary_large_image' },
      {
        name: 'twitter:title',
        content: `${this.course.title} ${this.$t('app.meta.title.suffix')}`,
      },
      {
        name: 'twitter:description',
        content: this.course.metadata.seo_description,
      },
      {
        name: 'twitter:image',
        content: this.course.thumbnail_url
          ? this.course.thumbnail_url
          : imgPlaceholder,
      },
    ];

    if (this.duration) {
      meta.push({
        name: 'duration_search_display',
        content: this.duration_search_display,
      });
    }

    if (this.offeringType === 'course') {
      meta.push(
        ...this.codeMetaTags,
        ...this.ctaButtonMeta,
        {
          name: 'entry_requirments_domestic',
          content: this.keyfactsEntry('domestic')?.shortValue || '',
        },
        {
          name: 'entry_requirments_international',
          content: this.keyfactsEntry('international')?.shortValue || '',
        },
        {
          name: 'entry_pathways_domestic',
          content: this.keyfactsEntryPathways('domestic')?.shortValue || '',
        },
        { name: 'study_level_search_filter', content: this.study_level },
        {
          name: 'study_mode_search_filter',
          content: stringifyArraySemiColon(this.course.filter_study_mode),
        },
        {
          name: 'start_dates_search_filter',
          content: stringifyArraySemiColon(
            this.filter_metadata.start_dates_search_filter,
          ),
        },
        {
          name: 'location_search_filter',
          content: stringifyArraySemiColon(
            this.filter_metadata.location_search_filter,
          ),
        },
        {
          name: 'qualification_type_search_display',
          content: this.display_metadata.qualification_type_search_display,
        },
        {
          name: 'intakes_search_display',
          content: stringifyArraySemiColon(
            this.display_metadata.intakes_search_display,
          ),
        },
        {
          name: 'intake_periods_search_filter',
          content: stringifyArraySemiColon(
            this.display_metadata.intake_periods_search_display,
          ),
        },
        {
          name: 'domestic_only_search_display',
          content: this.display_metadata.domestic_only_search_display,
        },
        {
          name: 'international_only_search_display',
          content: this.display_metadata.international_only_search_display,
        },
        {
          name: 'english_language_requirements',
          content:
            this.keyfactsEnglishLanguage('international').shortValue || '',
        },
        {
          name: 'duration',
          content: this.course.dotpoints.domestic.duration,
        },
        {
          name: 'fees_international',
          content: this.keyfactsFees('international')?.shortValue,
        },
        {
          name: 'fees_domestic',
          content: this.keyfactsFees('domestic')?.shortValue,
        },
        {
          name: 'intakes_international',
          content: this.keyfactsIntake('international')?.shortValue || '',
        },
        {
          name: 'intakes_domestic',
          content: this.keyfactsIntake('domestic')?.shortValue || '',
        },
        {
          name: 'duration_international',
          content: this.course.dotpoints.international.duration,
        },
      );

      if (
        this.filter_metadata.duration_in_months_search_filter &&
        this.filter_metadata.duration_in_months_search_filter !== '0'
      ) {
        meta.push({
          name: 'delivery_international',
          content: this.course.dotpoints.international.delivery_mode,
        });
        meta.push({
          name: 'duration_in_months_search_filter',
          content: this.filter_metadata.duration_in_months_search_filter,
        });
        meta.push({
          name: 'duration_filter',
          content: stringifyArraySemiColon(this.duration_filter),
        });
      }
    } else {
      meta.push({
        name: 'courses_available_in_search_display',
        content: this.relatedCoursesMeta,
      });
    }

    if (this.course.dotpoints) {
      if (
        this.course.dotpoints[this.residency] &&
        this.course.dotpoints[this.residency].delivery_mode
      ) {
        meta.push({
          name: 'delivery',
          content: this.course.dotpoints.domestic.delivery_mode,
        });
      }
    }

    return {
      title: `${this.title} ${this.$t('app.meta.title.suffix')}`,
      meta,
    };
  },
  computed: {
    codeMetaTags() {
      return this.course.codes.map((codeObj) => ({
        name: codeObj.title.replace(/ /g, '_').toLowerCase(),
        content: codeObj.code || '',
      }));
    },
    isMspaceCourse() {
      return this.course.metadata.mspace;
    },
    hasGraduateAccess() {
      if (
        this.residency === 'domestic' &&
        // eslint-disable-next-line camelcase
        this.course.entry_requirements?.additional_information?.graduate_access
          .show // eslint-disable-line camelcase
      ) {
        return true;
      }
      return false;
    },
    bannerType() {
      if (this.isMajor) {
        return this.course.offering_type;
      }
      return this.course.filter_second_level;
    },
    keyFactsPoints() {
      if (this.isMajor) {
        return [];
      }

      const array = [];

      if (this.course.dotpoints && this.course.dotpoints[this.residency]) {
        if (this.course.dotpoints[this.residency].duration !== '') {
          array.push({
            icon: 'clock',
            title: 'Duration',
            value: this.course.dotpoints[this.residency].duration,
          });
        }
        array.push({
          icon: 'location',
          title: 'Mode (Location)',
          value: this.delivery_mode_alt,
        });
      }

      array.push(this.keyfactsIntake(this.residency));

      if (this.keyfactsFees(this.residency)) {
        array.push(this.keyfactsFees(this.residency));
      }

      if (this.keyfactsEntry(this.residency)) {
        array.push(this.keyfactsEntry(this.residency));
      }

      if (this.keyfactsEntryPathways(this.residency)) {
        array.push(this.keyfactsEntryPathways(this.residency));
      }

      if (this.keyfactsEnglishLanguage(this.residency)) {
        array.push(this.keyfactsEnglishLanguage(this.residency));
      }

      return array;
    },
    activeYear() {
      return this.$store.getters.getFeatureFlags.activeYear;
    },
    linkPath() {
      return `${rootUrl}${this.basePath}/courses/${this.course.offering_level}/${this.course.slug}`;
    },
    isDomestic() {
      return this.residency === 'domestic';
    },
    isAtofCourse() {
      return (
        this.course.entry_requirements &&
        this.course.entry_requirements.template === 'grad_2023'
      );
    },
    isMajor() {
      const offeringType = ['major', 'minor', 'specialisation'];
      return offeringType.includes(this.course.offering_type);
    },
    isIncompatibleCourse() {
      const url = get(this.course, ['debug', 'matrix', 'api_url'], false);
      return url && url.includes('/courses-incompatible/');
    },
    applicationsClosed() {
      if (this.course.how_to_apply) {
        const ApplyNowArrayPosition = 0;
        return (
          this.howToApplyByResidency.call_to_actions[ApplyNowArrayPosition]
            ?.text === 'Applications closed'
        );
      }
      return false;
    },
    applicationsOpenSoon() {
      if (this.course.how_to_apply) {
        const ApplyNowArrayPosition = 0;
        return (
          this.howToApplyByResidency.call_to_actions[ApplyNowArrayPosition]
            ?.text === 'Applications open soon'
        );
      }
      return false;
    },
    howToApplyByResidency() {
      return this.course.how_to_apply[this.residency];
    },
    bannerAlert() {
      if (get(this.course, ['banner', 'alert', 'active_flag'], false)) {
        return get(this.course, ['banner', 'alert'], '');
      }
      return '';
    },
    title() {
      if (this.course && !this.course.error && this.$route.meta.length) {
        return `${this.course.title}${
          this.$route.meta[1] ? this.$route.meta[1].breadcrumb : ''
        }`;
      }
      return 'Find a course';
    },
    codes() {
      if (this.isMajor) {
        return [];
      }

      const { codes } = this.course;
      const courseCodeTitle = 'Course code';
      const courseCode = this.course.metadata.offering_code;
      const zero = 0;
      let returnCodes = [];

      if (
        this.course.offering_level === 'undergraduate' ||
        this.course.offering_level === 'honours'
      ) {
        const filterTitles = this.isDomestic
          ? ['International VTAC code', 'CRICOS code']
          : ['VTAC code'];
        returnCodes = codes.filter((obj) => !filterTitles.includes(obj.title));
        const cricosCode = returnCodes.find(
          (obj) => obj.title === 'CRICOS code',
        );
        if (cricosCode) {
          returnCodes.splice(returnCodes.indexOf(cricosCode), 1);
          returnCodes.push(cricosCode);
        }
      }
      if (this.course.offering_level === 'graduate') {
        if (!this.isDomestic) {
          returnCodes = codes.filter((obj) => obj.title === 'CRICOS code');
        }
      }

      if (!this.isIncompatibleCourse) {
        returnCodes.unshift({ title: courseCodeTitle, code: courseCode });
      }

      if (returnCodes.length === zero) {
        returnCodes.push({ title: ' ', code: ' ' });
      }
      return returnCodes;
    },
    course() {
      if (this.$store.getters['offerings/offering']) {
        return {
          ...this.$store.getters['offerings/offering'],
          loading: false,
        };
      }
      return {
        loading: true,
      };
    },
    relatedCoursesMeta() {
      return this.course.related_courses
        ?.map(
          (course) =>
            `${course.title}[${rootUrl}${this.basePath}/courses/${course.offering_level}/${course.slug}/]`,
        )
        .join('|');
    },
    topInterests() {
      return this.course.related_interests?.filter(
        (interest) => interest.top_interest,
      );
    },
    topInterestsMeta() {
      return this.topInterests?.map((interest) => interest.title).join(';');
    },
    offeringLevel() {
      return get(this.course, ['offering_level'], false);
    },
    offeringType() {
      return get(this.course, ['offering_type'], false);
    },
    filter_metadata() {
      return get(this.course, ['metadata', 'search_filter_metadata'], false);
    },
    display_metadata() {
      return get(this.course, ['metadata', 'search_display_metadata'], false);
    },
    bannerCta() {
      if (this.course.banner.cta.active_flag) {
        const bannerCtaData = this.course.banner.cta;

        if (this.offeringLevel === 'undergraduate') {
          bannerCtaData.left_icon = '';

          if (this.$route.path.includes('how-to-apply')) {
            bannerCtaData.active_flag = false;
          }
        }

        return bannerCtaData;
      }

      return {};
    },
    residency() {
      return this.$store.getters.studentType.residency.toLowerCase();
    },
    duration() {
      const { dotpoints } = this.course;

      if (
        dotpoints &&
        dotpoints[this.residency] &&
        dotpoints[this.residency].duration
      ) {
        return dotpoints[this.residency].duration;
      }

      return '';
    },
    duration_search_display() {
      return this.duration
        .replace(/years/g, 'Yrs')
        .replace(/year/g, 'Yr')
        .replace(/months/g, 'Mths')
        .replace(/month/g, 'Mth')
        .replace(/full time/g, '(FT)')
        .replace(/part time/g, '(PT)');
    },
    duration_filter() {
      const months = parseInt(
        this.filter_metadata.duration_in_months_search_filter,
        10,
      );

      const ranges = [
        { min: 0, max: 12, label: 'Less than 1 year' },
        { min: 12, max: 24, label: '1-2 years' },
        { min: 24, max: 36, label: '2-3 years' },
        { min: 36, max: 48, label: '3-4 years' },
        { min: 48, max: Infinity, label: 'Over 4 years' },
      ];

      const matchingRanges = ranges
        .filter((range) => months >= range.min && months <= range.max)
        .map((range) => range.label);

      return matchingRanges;
    },
    delivery_mode_alt() {
      if (this.course.dotpoints && this.course.dotpoints[this.residency]) {
        return this.course.dotpoints[this.residency].delivery_mode.replace(
          /Campus/g,
          'campus',
        );
      }
      return '';
    },
    study_level() {
      switch (this.course.filter_second_level) {
        case 'Undergraduate':
          return 'Undergraduate';
        case 'Graduate':
          return 'Graduate coursework';
        case 'Research':
          return 'Graduate research';
        default:
          return '';
      }
    },
    ctaButtonMeta() {
      return this.ctaButtonsArr.map((action) => ({
        name: action.text.replace(/ /g, '_').toLowerCase(),
        content: action.link || '',
      }));
    },
    ctaButtonsArr() {
      let { actions } = this.course.at_a_glance;
      actions = actions.filter((item) => item.link !== '');

      if (
        this.course.offering_type !== 'course' ||
        this.applicationsClosed ||
        this.applicationsOpenSoon
      ) {
        actions = actions.filter((item) => item.link !== '/how-to-apply');
      }

      // Modify the 'Find out how to apply' button to have 'How to apply' text and 'btn--primary' class
      const filteredActions = actions.map((action) => {
        let item;

        if (action.link === '/how-to-apply') {
          item = {
            ...action,
            class: 'btn--primary',
            text: 'How to apply',
            order: 1,
            link: `${this.linkPath}/how-to-apply/#nav`,
          };
        } else if (action.text === 'Enquire now') {
          item = {
            ...action,
            order: 2,
            text: 'Enquire',
            class: 'btn--secondary',
          };
        } else if (action.text === 'Register for updates') {
          if (
            this.course.offering_type !== 'course' ||
            this.applicationsClosed ||
            this.applicationsOpenSoon
          ) {
            item = { ...action, order: 0, class: 'btn--primary' };
          } else {
            item = { ...action, order: 3, class: 'btn--text' };
          }
        } else {
          item = { ...action, order: 4, class: 'btn--text' };
        }
        return item;
      });

      return filteredActions.sort((a, b) => a.order - b.order);
    },
    ctaButtonsAdditional() {
      if (this.applicationsClosed) {
        return this.$t('component.keyfacts.application-closed');
      }
      if (this.applicationsOpenSoon) {
        return this.$t('component.keyfacts.application-open-soon');
      }
      return '';
    },
  },
  mounted() {
    analyticsSetPage({
      page_name: `${this.course.title} ${this.$t('app.meta.title.suffix')}`,
    });

    this.observer = new IntersectionObserver(
      () => {
        const mobileWidth = 900;
        const viewportWidth = document
          .querySelector('#observerSensor')
          .getBoundingClientRect().width;

        if (
          document.querySelector('#observerSensor').getBoundingClientRect()
            .top < 30 // eslint-disable-line no-magic-numbers
        ) {
          this.$refs.stickyPanel.classList.remove('hide');

          if (
            viewportWidth < mobileWidth &&
            this.$refs.navlinks.toggleBlockActive()
          ) {
            this.$refs.navlinks.toggle();
          }
        } else {
          this.$refs.stickyPanel.classList.add('hide');
          if (
            viewportWidth < mobileWidth &&
            !this.$refs.navlinks.toggleBlockActive()
          ) {
            this.$refs.navlinks.toggleIgnoreJump();
          }
        }
      },
      // eslint-disable-next-line no-magic-numbers
      { threshold: 0, rootMargin: '-30px 0px -30px 0px' },
    );

    this.observer.observe(this.$refs.observerSensor);
  },
  methods: {
    keyfactsEntryPathways(residency) {
      const isDomestic = residency === 'domestic';
      if (
        isDomestic &&
        (this.course.offering_level === 'undergraduate' ||
          this.hasGraduateAccess)
      ) {
        return {
          icon: 'fork-right',
          title: 'Entry pathways',
          value: `Special entry options and Access Melbourne are available<br><a href="${this.linkPath}/entry-requirements/#nav">Learn more</a>`,
          shortValue: `Special entry options and Access Melbourne are available`,
        };
      }
      return false;
    },
    keyfactsEntry(residency) {
      const isDomestic = residency === 'domestic';
      if (
        this.course.offering_level !== 'undergraduate' ||
        !this.course.entry_requirements.atar
      ) {
        return false;
      }
      const entryReq = {
        icon: 'checklist',
        title: 'Entry requirements',
        value: '',
        shortValue: '',
      };
      const viewLink = `<a href='${this.linkPath}/entry-requirements/#nav'>View full entry requirements</a>`;
      if (this.course.entry_requirements.atar.isRangeCriteria) {
        entryReq.value = `Range of criteria used for selection ${viewLink}`;
        entryReq.shortValue = `Range of criteria used for selection`;
        return entryReq;
      }
      let atarScores = null;
      if (isDomestic && this.course.entry_requirements.atar.domestic) {
        atarScores = this.course.entry_requirements.atar.domestic;
      } else if (this.course.entry_requirements.atar.international) {
        atarScores = this.course.entry_requirements.atar.international;
      }
      if (atarScores) {
        if (atarScores.guaranteed?.score) {
          const guaranteed = !isDomestic
            ? 'International guaranteed ATAR'
            : 'Guaranteed ATAR';
          entryReq.value = `<strong>${atarScores.guaranteed.score}</strong> - ${guaranteed} ${atarScores.guaranteed.year}<br>`;
        }
        if (atarScores.lowest?.score && isDomestic) {
          entryReq.value += `<strong>${atarScores.lowest.score}</strong> - Lowest selection rank ${atarScores.lowest.year} (guide only)<br>`;
        }
        entryReq.shortValue = entryReq.value;
        entryReq.value += viewLink;
        return entryReq;
      }
      return false;
    },
    keyfactsFees(residency) {
      const isDomestic = residency === 'domestic';
      const minValidFeeLength = 2;
      const offeringLevel = get(this.course, ['offering_level'], false);
      const fee = {
        icon: 'dollar',
        title: 'Fees',
        value: '',
        shortValue: '',
      };

      if (isDomestic) {
        if (offeringLevel === 'undergraduate' || offeringLevel === 'honours') {
          fee.value = `${this.$t(
            'page.course.fees.overview.csp.keyfacts',
          )}<br><a href="${this.linkPath}/fees/#nav">Learn more</a>`;
          fee.shortValue = `${this.$t(
            'page.course.fees.overview.csp.keyfacts',
          )}`;
          return fee;
        }

        const csp = get(
          this.course.fee_data.domestic,
          ['commonwealth_supported_place'],
          false,
        );
        const rtp = get(
          this.course.fee_data.domestic,
          ['research_training_program'],
          false,
        );

        if (csp) {
          fee.value = `${this.$t(
            'page.course.fees.overview.csp.keyfacts',
          )}<br><a href="${this.linkPath}/fees/#nav">Learn more</a>`;
          fee.shortValue = `${this.$t(
            'page.course.fees.overview.csp.keyfacts',
          )}`;
          return fee;
        }
        if (rtp) {
          fee.value = `Australian Government Research Training Program (RTP) places available<br><a href="${this.linkPath}/fees/#nav">Learn more</a>`;
          fee.shortValue = `Australian Government Research Training Program (RTP) places available`;
          return fee;
        }
      }

      let selectedFee;

      if (this.feeAmountByYear(residency)) {
        selectedFee = this.feeAmountByYear(residency).find(
          (feeYearObj) =>
            parseInt(feeYearObj.year, 10) === parseInt(this.activeYear, 10),
        );
      }

      if (selectedFee) {
        if (
          selectedFee.first_year_fee &&
          selectedFee.first_year_fee.length > minValidFeeLength
        ) {
          fee.value = `AUD ${formatDollars(selectedFee.first_year_fee)} (${
            this.activeYear
          } ${this.$t(
            'page.course.fees.overview.feeitems.yearly.title',
          ).toLowerCase()})`;
          fee.shortValue = `AUD ${formatDollars(selectedFee.first_year_fee)} (${
            this.activeYear
          } ${this.$t(
            'page.course.fees.overview.feeitems.yearly.title',
          ).toLowerCase()})`;
        } else if (
          selectedFee.total_fee &&
          selectedFee.total_fee.length > minValidFeeLength
        ) {
          fee.value = `AUD ${formatDollars(selectedFee.total_fee)} (${
            this.activeYear
          } ${this.$t(
            'page.course.fees.overview.feeitems.total.title',
          ).toLowerCase()})`;
          fee.shortValue = fee.value;
        } else {
          return null;
        }
        if (isDomestic) {
          fee.value +=
            '. Commonwealth Supported Places (CSPs) are not available';
          fee.shortValue += fee.value;
        }
        fee.value += `<br><a href="${this.linkPath}/fees/#nav">Learn more</a>`;
        return fee;
      }

      return null;
    },
    feePlace(residency) {
      const isDomestic = residency === 'domestic';
      if (isDomestic) {
        return get(
          this.course.fee_data.domestic,
          ['australian_fee_place'],
          false,
        );
      }
      return get(
        this.course.fee_data.international,
        ['international_fee_place'],
        false,
      );
    },
    feeAmountByYear(residency) {
      return this.feePlace(residency).fee_amount_by_year;
    },
    keyfactsEnglishLanguage(residency) {
      const isDomestic = residency === 'domestic';
      let englishRequirementsValue = '';
      if (!isDomestic) {
        if (this.course.slug === 'bachelor-of-oral-health') {
          englishRequirementsValue = 'IELTS 7 (with no bands less than 7)<br>';
        } else if (
          this.course.offering_level === 'undergraduate' ||
          this.course.offering_level === 'honours'
        ) {
          englishRequirementsValue =
            'IELTS 6.5 (with no band less than 6.0)<br>';
        } else if (this.isAtofCourse) {
          const ieltsScore =
            this.course.entry_requirements.admission_criteria
              .english_requirements_scores.ielts.score;
          const ieltsText =
            this.course.entry_requirements.admission_criteria
              .english_requirements_scores.ielts.text;
          englishRequirementsValue = `IELTS ${ieltsScore} ${ieltsText}<br>`;
        } else {
          englishRequirementsValue = '';
        }
        return {
          icon: 'record-voice-over',
          title: 'English language requirements',
          value: `${englishRequirementsValue}<a href="${this.linkPath}/entry-requirements/#nav">View full entry requirements</a>`,
          shortValue: `${englishRequirementsValue}`,
        };
      }
      return false;
    },
    dotpoints(residency) {
      return get(this.course, ['dotpoints', residency], false);
    },
    keyfactsIntake(residency) {
      const residencyKey =
        residency === 'domestic' ? 'domestic' : 'international';
      const zero = 0;
      const intake = {
        icon: 'calendar-month',
        title: 'Intake',
        value: '',
        shortValue: '',
      };
      const keyDatesLink = `<a href='${this.linkPath}/how-to-apply/#nav'>Key dates</a>`;
      const howToApplyLink = `<a href='${this.linkPath}/how-to-apply/#nav'>How to apply</a>`;
      const intakes = this.dotpoints(residencyKey).commencement_dates;

      if (intakes && intakes.length > zero) {
        intake.value = `${this.extractMonths(
          this.dotpoints(residencyKey).commencement_dates,
        )}<br>${keyDatesLink}`;
        intake.shortValue = `${this.extractMonths(
          this.dotpoints(residencyKey).commencement_dates,
        )}`;
      } else if (this.applicationsClosed || this.applicationsOpenSoon) {
        intake.value = `See ${howToApplyLink}`;
        intake.shortValue = '';
      } else if (
        this.course.offering_level === 'graduate' &&
        this.course.category === 'research'
      ) {
        intake.value = `Flexible <br> See ${howToApplyLink}`;
        intake.shortValue = 'Flexible';
      } else {
        intake.value = `See ${howToApplyLink}`;
        intake.shortValue = '';
      }
      return intake;
    },
    extractMonths(text) {
      const months = text.match(
        /(?:January|February|March|April|May|June|July|August|September|October|November|December)/g,
      );
      if (months) {
        const monthOrder = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        months.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));
        const uniqueMonths = [...new Set(months)];
        return uniqueMonths.join(', ');
      }
      return '';
    },
  },
  meta: {
    pagetype: 'course',
  },
};
</script>

<style lang="postcss">
.stickyPanel {
  position: sticky;
  transition: transform 0.3s ease-in-out;
  top: 0;
  z-index: calc(var(--z-navigation) - 1);
  background-color: white;

  .btn--text {
    display: none;
  }
}

.hide > :not(.nav--toggle) {
  display: none;
}

.section-header-nav-links {
  background-color: var(--col-sage-light-100);

  .section__inner {
    padding: 0 var(--sp-3);
  }
}
</style>
